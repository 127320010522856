var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2 breadcrumbs hidden-xs",class:{ 'd-none': !_vm.$route.path.startsWith('/triedy') }},[_c('div',{staticClass:"margin"},[_c('p',{staticClass:"d-flex cursor-pointer font-weight-bold",on:{"click":function($event){return _vm.$router.push('/profil')}}},[_vm._v(" Úvod ")]),_c('div',[_c('span',{staticClass:"mx-2"},[_vm._v("•")]),_c('p',{staticClass:"d-flex",class:{ 'cursor-pointer font-weight-bold': _vm.isEmptyParams() },on:{"click":function($event){_vm.isEmptyParams() && _vm.$router.push('/triedy')}}},[_vm._v(" Triedy ")])]),(_vm.groupName)?_c('div',[_c('span',{staticClass:"mx-2"},[_vm._v("•")]),_c('p',{class:[
          _vm.studentName || _vm.$route.params.taskId || _vm.$route.query.tabIndex != 0
            ? 'font-weight-bold cursor-pointer'
            : '',
        ],on:{"click":function($event){((_vm.$route.params.taskId || _vm.$route.params.studentId) &&
            _vm.$router.replace({
              name: 'Trieda',
              params: { id: _vm.$route.params.id },
              query: { tabIndex: 1 },
            })) ||
            (_vm.$route.query.tabIndex === '1' &&
              _vm.$router.push({ query: { tabIndex: 0 } }))}}},[_vm._v(" "+_vm._s(_vm.groupName)+" ")])]):_vm._e(),(_vm.studentName)?_c('div',[_c('span',{staticClass:"mx-2"},[_vm._v("•")]),_c('p',[_vm._v(_vm._s(_vm.studentName))])]):_vm._e(),(_vm.$route.query.tabIndex === 1 || _vm.$route.params.taskId)?_c('div',[_c('span',{staticClass:"mx-2"},[_vm._v("•")]),_c('p',{class:{ 'cursor-pointer font-weight-bold': _vm.$route.params.taskId },on:{"click":function($event){_vm.$route.params.taskId &&
            _vm.$router.push(`/triedy/${_vm.$route.params.id}?tabIndex=1`)}}},[_vm._v(" Úlohy ")])]):_vm._e(),(_vm.taskName)?_c('div',[_c('span',{staticClass:"mx-2"},[_vm._v("•")]),_c('p',[_vm._v(_vm._s(_vm.taskName))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }