<template>
  <div
    class="p-2 breadcrumbs hidden-xs"
    :class="{ 'd-none': !$route.path.startsWith('/triedy') }"
  >
    <div class="margin">
      <p
        class="d-flex cursor-pointer font-weight-bold"
        @click="$router.push('/profil')"
      >
        Úvod
      </p>
      <div>
        <span class="mx-2">&bull;</span>
        <p
          class="d-flex"
          :class="{ 'cursor-pointer font-weight-bold': isEmptyParams() }"
          @click="isEmptyParams() && $router.push('/triedy')"
        >
          Triedy
        </p>
      </div>
      <div v-if="groupName">
        <span class="mx-2">&bull;</span>
        <p
          :class="[
            studentName || $route.params.taskId || $route.query.tabIndex != 0
              ? 'font-weight-bold cursor-pointer'
              : '',
          ]"
          @click="
            (($route.params.taskId || $route.params.studentId) &&
              $router.replace({
                name: 'Trieda',
                params: { id: $route.params.id },
                query: { tabIndex: 1 },
              })) ||
              ($route.query.tabIndex === '1' &&
                $router.push({ query: { tabIndex: 0 } }))
          "
        >
          {{ groupName }}
        </p>
      </div>
      <div v-if="studentName">
        <span class="mx-2">&bull;</span>
        <p>{{ studentName }}</p>
      </div>
      <div v-if="$route.query.tabIndex === 1 || $route.params.taskId">
        <span class="mx-2">&bull;</span>
        <p
          :class="{ 'cursor-pointer font-weight-bold': $route.params.taskId }"
          @click="
            $route.params.taskId &&
              $router.push(`/triedy/${$route.params.id}?tabIndex=1`)
          "
        >
          Úlohy
        </p>
      </div>
      <div v-if="taskName">
        <span class="mx-2">&bull;</span>
        <p>{{ taskName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, get } from "lodash";
import { mapGetters } from "vuex";
import axios from "/utils/axios";
import { getUserDisplayName } from "/utils/helpers";

const api = {
  admin: {
    getGroup: (groupId) => {
      return axios.getAuth(`groups/${groupId}`);
    },
    getMember: (memberId) => {
      return axios.getAuth(`group-members/${memberId}`);
    },
    getTask: (taskId) => {
      return axios.getAuth(`tasks/${taskId}`);
    },
  },
  member: {
    getGroup: (groupId) => {
      return axios.getAuth(`groups/${groupId}`);
    },
    getTask: (taskId) => {
      return axios.getAuth(`tasks/${taskId}`);
    },
  },
};

export default {
  data() {
    return {
      groupName: null,
      studentName: null,
      taskName: null,
      atTasks: false,
    };
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler(val) {
        val.id ? this._loadGroupName(val.id) : (this.groupName = null);

        val.studentId
          ? this._loadStudentName(val.studentId)
          : (this.studentName = null);

        val.taskId
          ? this._loadTaskName(val.id, val.taskId)
          : (this.taskName = null);
      },
    },
    "$route.query.tabIndex": {
      immediate: true,
      handler(tabIndex) {
        this.atTasks = tabIndex ? true : false;
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
  },
  methods: {
    isEmptyParams() {
      return !isEmpty(this.$route.params);
    },
    async _loadGroupName(groupId) {
      const handler = this.isAdmin ? api.admin.getGroup : api.member.getGroup;
      const group = await handler(groupId);

      this.groupName = `${get(group.data, "attributes.name")} ${get(
        group.data,
        "attributes.subject"
      )}`;
    },
    async _loadStudentName(mid) {
      const student = await api.admin.getMember(mid);

      this.studentName = getUserDisplayName(student.data);
    },
    async _loadTaskName() {
      const handler = this.isAdmin ? api.admin.getTask : api.member.getTask;
      const task = await handler(this.$route.params.taskId);

      this.taskName = get(task.data, "attributes.course.data.attributes.name");
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  .margin {
    padding-left: 20%;
  }
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #dfe0e4;
  border-bottom: 1px solid #dfe0e4;
}

div {
  display: flex;
}

p {
  margin: 0;
  color: var(--a-color-black);
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-bold {
  color: var(--a-color-black);
}

@media only screen and (max-width: 600px) {
  .breadcrumbs {
    display: none !important;
  }
}
</style>
